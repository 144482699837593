import {
    faMale,
    faPaperPlane,
    faStar,
    faFlag,
    faVirusSlash,
    faBusinessTime,
    faCouch,
    faMap,
    faHeadphones,
    faWifi,
    faPhone,
    faMosque,
    faBlog,
    faCity,
    faMusic,
    faTv,
    faPlug,
    faToilet,
    faBroadcastTower,
    faLightbulb,
    faHeartbeat,
    faEllipsisV,
    faBed,
    faBriefcase,
    faChild,
    faSpa,
    faLaptopHouse,
    faUtensils,
    faGifts,
    faChartLine,
    faDotCircle,
    faDoorOpen,
    faUmbrellaBeach,
    faSwimmingPool,
    faBaby,
    faBroom,
    faWheelchair,
    faBusAlt,
    faPlaneDeparture,
    faFileAlt,
    faUserClock,
    faUserCircle,
    faUserCheck,
    faUser,
    faConciergeBell,
    faStopwatch,
    faSignOutAlt,
    faCheck,
    faSuitcase,
    faBook,
    faTag,
    faLock,
    faChevronRight,
    faRoute,
    faChevronLeft,
    faBus,
    faPlane,
    faLongArrowAltRight,
    faGlobeAmericas,
    faMapMarker,
    faMapMarkerAlt,
    faMarker,
    faAngleDoubleRight,
    faLongArrowAltLeft,
    faCar,
    faCaretRight,
    faArrowLeft,
    faArrowRight,
    faQuestion,
    faChevronDown,
    faChevronUp,
    faPen,
    faInfo,
    faInfoCircle,
    faStore,
    faStoreAlt,
    faTrash,
    faTrashAlt,
    faUndo,
    faPlus,
    faPlusCircle,
    faSearch,
    faHistory,
    faEdit,
    faKey,
    faTimes,
    faCaretDown,
    faUserFriends,
    faMinus,
    faMapPin,
    faEyeSlash,
    faMapMarkedAlt,
    faPercent,
    faSlidersH,
    faExpandArrowsAlt,
    faGift,
    faCheckDouble,
    faCaretUp,
    faHeadset,
    faExclamationTriangle,
    faWallet,
    faIdCard,
    faHome,
    faTachometerAlt,
    faGasPump,
    faFilter,
    faSuitcaseRolling,
    faChair,
    faHotel,
    faDownload,
    faThumbsUp,
    faAngleDoubleLeft,
    faFile,
    faAddressCard,
    faTicketAlt,
    faMoon,
    faAngleDoubleUp,
    faShareAlt,
    faCrosshairs,
    faSpinner,
    faQuoteLeft,
    faTimesCircle,
    faFighterJet,
    faLiraSign,
    faParking,
    faHourglassHalf,
    faExclamation,
    faCopy,
    faCalendarAlt,
    faEnvelope,
    faEye,
    faPlaneArrival,
    faLink,
} from "@fortawesome/free-solid-svg-icons";

const fas = [
    faMap,
    faParking,
    faLink,
    faPlaneArrival,
    faCalendarAlt,
    faEnvelope,
    faHourglassHalf,
    faBlog,
    faCopy,
    faLiraSign,
    faExclamation,
    faEye,
    faTimesCircle,
    faFighterJet,
    faMale,
    faSlidersH,
    faQuoteLeft,
    faPaperPlane,
    faStar,
    faFlag,
    faVirusSlash,
    faBusinessTime,
    faSpinner,
    faCouch,
    faHeadphones,
    faWifi,
    faPhone,
    faMosque,
    faMusic,
    faTv,
    faPlug,
    faToilet,
    faBroadcastTower,
    faCity,
    faLightbulb,
    faHeartbeat,
    faEllipsisV,
    faBed,
    faUmbrellaBeach,
    faBriefcase,
    faChild,
    faSpa,
    faLaptopHouse,
    faUtensils,
    faGifts,
    faChartLine,
    faDotCircle,
    faDoorOpen,
    faSwimmingPool,
    faBaby,
    faBroom,
    faWheelchair,
    faBusAlt,
    faPlaneDeparture,
    faFileAlt,
    faUserClock,
    faUserCircle,
    faUserCheck,
    faUser,
    faConciergeBell,
    faStopwatch,
    faSignOutAlt,
    faCheck,
    faSuitcase,
    faBook,
    faTag,
    faLock,
    faChevronRight,
    faRoute,
    faChevronLeft,
    faBus,
    faPlane,
    faLongArrowAltRight,
    faGlobeAmericas,
    faMapMarker,
    faMapMarkerAlt,
    faMarker,
    faAngleDoubleRight,
    faLongArrowAltLeft,
    faCar,
    faCaretRight,
    faArrowLeft,
    faArrowRight,
    faQuestion,
    faChevronDown,
    faChevronUp,
    faPen,
    faInfo,
    faInfoCircle,
    faStore,
    faStoreAlt,
    faTrash,
    faTrashAlt,
    faUndo,
    faPlus,
    faPlusCircle,
    faSearch,
    faHistory,
    faEdit,
    faKey,
    faTimes,
    faCaretDown,
    faUserFriends,
    faMinus,
    faMapPin,
    faEyeSlash,
    faMapMarkedAlt,
    faPercent,
    faExpandArrowsAlt,
    faGift,
    faCheckDouble,
    faCaretUp,
    faHeadset,
    faExclamationTriangle,
    faWallet,
    faIdCard,
    faHome,
    faMap,
    faTachometerAlt,
    faGasPump,
    faFilter,
    faSuitcaseRolling,
    faChair,
    faHotel,
    faDownload,
    faThumbsUp,
    faAngleDoubleLeft,
    faFile,
    faAddressCard,
    faTicketAlt,
    faMoon,
    faAngleDoubleUp,
    faShareAlt,
    faCrosshairs,
]

export default fas
