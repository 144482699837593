import fas from './Fas.js'

import {
    faFacebookF,
    faInstagram,
    faTwitter,
    faLinkedin,
    faYoutube,
    faUsb,
    faPinterestP,
} from "@fortawesome/free-brands-svg-icons";

import {
    faClock,
    faCheckCircle,
    faImages,
    faCalendar,
    faCalendarAlt,
    faMap,
    faChartBar,
    faEye,
    faQuestionCircle,
    faCreditCard,
    faTimesCircle,
    faPaperPlane,
    faHeart,
    faCopy,
    faCircle,
    faPlusSquare,
    faTrashAlt,
    faUserCircle,
}  from "@fortawesome/free-regular-svg-icons";


export const fontIcons = [
    ...fas,



    faFacebookF,
    faInstagram,
    faTwitter,
    faLinkedin,
    faYoutube,
    faUsb,
    faPinterestP,




    faClock,
    faCheckCircle,
    faImages,
    faCalendar,
    faCalendarAlt,
    faMap,
    faChartBar,
    faEye,
    faQuestionCircle,
    faCreditCard,
    faUserCircle,
    faTimesCircle,
    faPaperPlane,
    faHeart,
    faCopy,
    faCircle,
    faPlusSquare,
    faTrashAlt,
]
